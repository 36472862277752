import * as ProSolidSvgIcons from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { Link, useLocation, useParams } from 'react-router-dom'
import * as Api from 'src/api'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import Warning from 'src/imgs/classroom_icon.svg'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import BackButton from 'src/views/components/BackButton'
import { MediaFilesDownload } from 'src/views/components/MediaFilesDownload'
import NoContent from 'src/views/components/NoContent'
import UserPhoto from 'src/views/components/UserPhoto'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function LecturerPortfolioWorkPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:work')} />
      <SuspenseWrapper>
        <PageContent />
      </SuspenseWrapper>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const headers = useAuthenticatedHeaders()
  const params = useParams()
  const { pathname } = useLocation()
  const t = useTranslatable()
  const locale = useLocale()

  const section = useApi({
    endpoint: Api.getLecturerPortfolioSection,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.sectionId!,
        },
      }),
      [headers, params.sectionId]
    ),
  })

  const work = useApi({
    endpoint: Api.getLecturerPortfolioSectionWork,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          SectionId: params.sectionId!,
          id: params.workId!,
        },
      }),
      [headers, params.workId, params.sectionId]
    ),
  })

  const breadcrumbsItems = [
    { page: `${t('portfolio:dashboard')}`, path: `/${locale}/lecturer/portfolio` },
    { page: `${section.data.name}`, path: `/${locale}/lecturer/portfolio/sections/${params.sectionId}/works` },
    { page: `${t('portfolio:work')}`, path: pathname },
  ]

  return (
    <Section
      title={t('portfolio:portfolio_work')}
      icon={<Portfolio />}
      breadcrubms={breadcrumbsItems}
      rightElement={<BackButton link={`/${locale}/lecturer/portfolio/sections/${params.sectionId}/works`} />}
    >
      <div className="text-primaryTextColor">
        {work != null ? (
          <>
            <div className="mb-6">
              <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:work_competencies') + `: `}</h2>
              {work.data.competencies !== null ? (
                work.data.competencies?.map((competency) => (
                  <div className="mt-2" key={competency.id}>
                    <span className="font-bold">{competency.name}</span>
                    {params.sectionId == '2' ? (
                      <Table.Table className="mt-2 w-full">
                        <Table.Thead>
                          <Table.Th className="w-[10%]">{t('portfolio:criterion')}</Table.Th>
                          <Table.Th className="w-3/5">{t('portfolio:description')}</Table.Th>
                          {work.data?.status === 'STATUS_EVALUATED' && (
                            <>
                              <Table.Th className="!text-center">{t('common:score')}</Table.Th>
                              <Table.Th className="w-1/5">{t('portfolio:work_feedback')}</Table.Th>
                            </>
                          )}
                        </Table.Thead>
                        <Table.Tbody>
                          {competency.criterias?.map((criteria) => (
                            <Table.Tr key={criteria.id} className="w-full">
                              <Table.Td>
                                <div className="my-3">
                                  <span>{criteria.name}</span>
                                </div>
                              </Table.Td>
                              <Table.Td>
                                <div className="my-3">
                                  <span>{criteria.text}</span>
                                </div>
                              </Table.Td>
                              {work.data?.status === 'STATUS_EVALUATED' && (
                                <>
                                  <Table.Td className="flex justify-center align-middle">
                                    {criteria.score?.score}
                                  </Table.Td>
                                  <Table.Td>{criteria.score?.feedback}</Table.Td>
                                </>
                              )}
                            </Table.Tr>
                          ))}
                        </Table.Tbody>
                      </Table.Table>
                    ) : (
                      <Table.Table className="mt-2 w-full">
                        <Table.Thead>
                          <Table.Th className="w-[10%]">{t('portfolio:criterion')}</Table.Th>
                          {work.data?.status === 'STATUS_EVALUATED' && (
                            <>
                              <Table.Th className="!text-center">{t('common:score')}</Table.Th>
                              <Table.Th className="w-1/5">{t('portfolio:work_feedback')}</Table.Th>
                            </>
                          )}
                        </Table.Thead>
                        <Table.Tbody>
                          {competency.criterias?.map((criteria) => (
                            <Table.Tr key={criteria.id} className="w-full">
                              <Table.Td>
                                <div className="my-3">
                                  <span>{criteria.name}</span>
                                </div>
                              </Table.Td>
                              {work.data?.status === 'STATUS_EVALUATED' && (
                                <>
                                  <Table.Td className="flex justify-center align-middle">
                                    {criteria.score?.score}
                                  </Table.Td>
                                  <Table.Td>{criteria.score?.feedback}</Table.Td>
                                </>
                              )}
                            </Table.Tr>
                          ))}
                        </Table.Tbody>
                      </Table.Table>
                    )}
                  </div>
                ))
              ) : (
                <span> - </span>
              )}
            </div>
            {params.sectionId == '2' ? null : (
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:work_description') + `: `}</h2>
                {work.data.text != null ? (
                  <span dangerouslySetInnerHTML={{ __html: work.data.text }} />
                ) : (
                  <span> - </span>
                )}
              </div>
            )}

            <div className="mb-6">
              <h2 className="mb-3 border-b border-borderGrey py-2">{t('student:student') + `: `}</h2>
              {work.data.student != null ? (
                <div className="text-title">
                  <UserPhoto className="scale-[0.7]" user={work.data.student} />
                  <Link
                    to={`/${locale}/users/${work.data.student?.uid}`}
                    className="ml-2 whitespace-nowrap align-middle !no-underline hover:underline"
                    data-tooltip-id={`${work.data.id}-lecturer`}
                  >
                    <span className="ml-2">{work.data.student?.fullName}</span>
                  </Link>
                </div>
              ) : (
                <span> - </span>
              )}
            </div>
            <div className="mb-6">
              <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:work_status') + `: `}</h2>
              {work.data.status != null ? (
                <div>
                  {work.data.status === 'STATUS_PENDING' && <span>{t('portfolio:status_pending')}</span>}
                  {work.data.status === 'STATUS_EVALUATED' ? <span>{t('portfolio:status_evaluated')}</span> : null}
                  {work.data.status === 'STATUS_REJECTED' && <span>{t('portfolio:status_rejected')}</span>}
                </div>
              ) : (
                <span> - </span>
              )}
            </div>
            {work.data.comment ? (
              <div className="mb-6">
                <h2 className="mb-3 border-b border-borderGrey py-2">{t('portfolio:comment') + `: `}</h2>
                <div className="mb-6">{work.data.comment}</div>
              </div>
            ) : null}

            {work.data.mediaFiles !== undefined && work.data.mediaFiles.length > 0 ? (
              <div className="mb-8">
                <h2 className="works-section mb-3 border-b border-borderGrey py-2">
                  {t('file:attached_files') + `: `}
                </h2>
                <div className="text-title">
                  <div className="row-auto my-3">
                    <div className="col-auto mb-2">
                      {work.data.mediaFiles.map((file) => (
                        <div key={file.id} className="flex">
                          <span className="ml-2">
                            <FontAwesomeIcon icon={ProSolidSvgIcons.faDownload} />
                          </span>
                          <MediaFilesDownload
                            mediaFileId={file.id}
                            className="mx-2 text-primaryTextColor"
                            style={{ height: 'fit-content' }}
                          >
                            <div>{file.originalName}</div>
                          </MediaFilesDownload>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </>
        ) : (
          <NoContent
            header={t('error:recommendations_not_found')}
            image={Warning}
            subHeader={t('course:for_request_recommendation')}
          />
        )}
        {work.data.manage?.canSubmitScore === true &&
        work.data.status !== 'STATUS_EVALUATED' &&
        work.data.status !== 'STATUS_REJECTED' ? (
          <div className="!mb-9 flex items-center justify-end">
            <Link to="edit">
              <Button variant="blue" className="flex items-center">
                {t('portfolio:evaluation')}
              </Button>
            </Link>
            <Link to="reject" className="ml-3">
              <Button variant="red" className="flex items-center">
                {t('portfolio:reject')}
              </Button>
            </Link>
          </div>
        ) : null}
      </div>
    </Section>
  )
}
