// import { useFormikContext } from 'formik'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'
import * as Yup from 'yup'

interface FormikValues {
  readonly comment: string
}

export default function LecturerPortfolioRejectPage(): JSX.Element | null {
  const t = useTranslatable()

  return (
    <>
      <Helmet title={t('portfolio:reject')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const t = useTranslatable()
  const locale = useLocale()
  const params = useParams() as {
    readonly sectionId: string
    readonly workId: string
  }
  const { pathname } = useLocation()

  const navigate = useNavigate()
  const headers = useAuthenticatedHeaders()
  const initialValues: FormikValues = {
    comment: '',
  }

  const section = useApi({
    endpoint: Api.getLecturerPortfolioSection,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.sectionId!,
        },
      }),
      [headers, params.sectionId]
    ),
  })

  const breadcrumbsItems = [
    { page: `${t('portfolio:dashboard')}`, path: `/${locale}/lecturer/portfolio` },
    { page: `${section.data.name}`, path: `/${locale}/lecturer/portfolio/sections/${params.sectionId}/works` },
    {
      page: `${t('portfolio:work')}`,
      path: `/${locale}/lecturer/portfolio/sections/${params.sectionId}/works/${params.workId}`,
    },

    { page: `${t('portfolio:reject')}`, path: pathname },
  ]

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    try {
      await Api.patchLecturerPortfolioSectionsWorksReject({
        body: values,
        args: {
          id: params.sectionId,
          workId: params.workId,
        },
        headers,
      })

      setSuccessfullySubmited(true)
    } catch (error) {
      console.error('Error submitting form:', error)
      toast.error('Failed to submit form. Please try again later.')
    }
  }

  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/lecturer/portfolio/sections/${params.sectionId}/works`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited])
  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object().shape({
        comment: Yup.string().required(t('message:body_is_required')),
      })}
      isConfirmable={!successfullySubmited}
    >
      <Section
        title={t('portfolio:portfolio_reject')}
        icon={<Portfolio />}
        breadcrubms={breadcrumbsItems}
        rightElement={
          <BackButton link={`/${locale}/lecturer/portfolio/sections/${params.sectionId}/works/${params.workId}`} />
        }
      >
        <SuspenseWrapper>
          <FormContent />
        </SuspenseWrapper>
      </Section>
    </Form>
  )
}

function FormContent(): React.ReactElement | null {
  const t = useTranslatable()

  return (
    <>
      <FormError />
      <div className="mb-6">
        <div className="my-3 w-full">
          <TextareaInput
            name="comment"
            required
            label={t('portfolio:comment')}
            placeholder={t('portfolio:comment')}
            className="className='!mt-0' h-[160px] w-full rounded-md border !border-borderColor px-4 py-2 text-base"
          />
        </div>
      </div>
      <div className="my-3 flex w-full justify-end">
        <Button type="submit" variant="blue">
          {t('portfolio:reject')}
        </Button>
      </div>
    </>
  )
}
