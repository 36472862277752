import { useFormikContext } from 'formik'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Api from 'src/api'
// import { ArrowRight } from 'src/assets/icons/customIcons/ArrowRight'
import Portfolio from 'src/assets/icons/customIcons/menu-icons/Portfolio'
import { useApi } from 'src/helpers/hooks'
import { useAuthenticatedHeaders } from 'src/hooks/auth/app'
import { useLocale } from 'src/hooks/locale/locale'
import { useTranslatable } from 'src/hooks/locale/utils'
import { Button } from 'src/tailwind/components/Button'
import { Section } from 'src/tailwind/components/Section'
import * as Table from 'src/tailwind/components/Table'
import BackButton from 'src/views/components/BackButton'
import { ErrorBoundary } from 'src/views/components/Error'
import ErrorElement from 'src/views/components/ErrorElement'
import Loader from 'src/views/components/Loader'
import PopoverComponent from 'src/views/components/PopoverComponent'
import { Form } from 'src/views/components/forms/formik/Form'
import { FormError } from 'src/views/components/forms/formik/FormError'
import SelectInput from 'src/views/components/forms/formik/SelectInput'
import TextareaInput from 'src/views/components/forms/formik/TextareaInput'
import SuspenseWrapper from 'src/views/includes/SuspenseWrapper'

export default function LecturerPortfolioEvaluationPage(): JSX.Element | null {
  const t = useTranslatable()
  return (
    <>
      <Helmet title={t('portfolio:evaluation')} />
      <React.Suspense fallback={<Loader className="m-auto flex" />}>
        <ErrorBoundary errorElement={<ErrorElement />}>
          <PageContent />
        </ErrorBoundary>
      </React.Suspense>
    </>
  )
}

function PageContent(): JSX.Element | null {
  const t = useTranslatable()
  const params = useParams() as {
    readonly sectionId: string
    readonly workId: string
  }
  const { pathname } = useLocation()
  const locale = useLocale()
  const headers = useAuthenticatedHeaders()
  const [successfullySubmited, setSuccessfullySubmited] = React.useState(false)
  const navigate = useNavigate()

  const section = useApi({
    endpoint: Api.getLecturerPortfolioSection,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          id: params.sectionId!,
        },
      }),
      [headers, params.sectionId]
    ),
  })

  const work = useApi({
    endpoint: Api.getLecturerPortfolioSectionWork,
    params: React.useMemo(
      () => ({
        headers,
        args: {
          SectionId: params.sectionId!,
          id: params.workId!,
        },
      }),
      [headers, params.sectionId, params.workId]
    ),
    suspense: false,
  })

  const breadcrumbsItems = [
    { page: `${t('portfolio:dashboard')}`, path: `/${locale}/lecturer/portfolio` },
    { page: `${section.data.name}`, path: `/${locale}/lecturer/portfolio/sections/${params.sectionId}/works` },
    {
      page: `${t('portfolio:work')}`,
      path: `/${locale}/lecturer/portfolio/sections/${params.sectionId}/works/${params.workId}`,
    },

    { page: `${t('portfolio:evaluation')}`, path: pathname },
  ]

  const [initialValues, setInitialValues] = React.useState(null)

  const setFormValues = (competencies: any) => {
    const initialValues: any = { scores: {}, comment: '' }

    competencies.map((competency: any) => {
      const competencyId = competency.id
      initialValues.scores[competencyId] = { criterias: {} }

      competency.criterias.map((criteria: any) => {
        const criteriaId = criteria.id
        initialValues.scores[competencyId].criterias[criteriaId] = {
          score: '',
          feedback: '',
        }
      })
    })

    return initialValues
  }

  React.useEffect(() => {
    if (work.data?.competencies) {
      setInitialValues(setFormValues(work.data.competencies))
    }
  }, [work.data?.competencies])

  const handleSubmit = async (values: any): Promise<void> => {
    await Api.patchLecturerPortfolioSectionsWorks({
      body: values,
      args: {
        sectionId: params.sectionId,
        workId: params.workId,
      },
      headers,
    })
    toast.success(t('common:updated_successfully'))
    setSuccessfullySubmited(true)
  }

  React.useEffect(() => {
    if (successfullySubmited) {
      navigate(`/${locale}/lecturer/portfolio/sections/${params.sectionId}/works/${params.workId}`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successfullySubmited, params.workId])

  if (work.data != null) {
    return (
      <Form initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
        <FormError />
        <Section
          title={t('portfolio:portfolio_evaluation')}
          icon={<Portfolio />}
          breadcrubms={breadcrumbsItems}
          rightElement={
            <BackButton link={`/${locale}/lecturer/portfolio/sections/${params.sectionId}/works/${params.workId}`} />
          }
        >
          <SuspenseWrapper>
            <FormContent work={work.data} />
          </SuspenseWrapper>
        </Section>
      </Form>
    )
  } else return null
}

function FormContent({ work }: { work: Api.LecturerPortfolioUserWork }): React.ReactElement | null {
  const t = useTranslatable()
  const formik = useFormikContext()
  // const [show, setShow] = React.useState<boolean>(false)
  // const [element, setElement] = useState<HTMLDivElement | null>(null)

  const scoreOptions = (min: number, max: number) => {
    const options = []
    for (let i = min; i <= max; i++) {
      options.push({ value: i.toString(), label: i.toString() })
    }
    return options
  }

  return (
    <>
      <div className="mb-6">
        {work.competencies?.map((competency) => (
          <div key={competency.id}>
            <Table.Table className="mb-6 mt-3 !overflow-x-visible" key={competency.id}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>{competency.name}</Table.Th>
                  <Table.Th className="w-[160px]" />
                  <Table.Th />
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {competency.criterias?.map((criteria) => (
                  <Table.Tr key={criteria.id}>
                    <Table.Td className="!align-top">
                      {t('portfolio:criterion')}: {criteria.name}
                    </Table.Td>
                    <Table.Td className="!align-top">
                      <div className="flex !align-top">
                        <SelectInput
                          name={`scores.${competency.id}.criterias.${criteria.id}.score`}
                          placeholder={t('common:choose')}
                          options={scoreOptions(criteria.min, criteria.max)}
                        />
                        <div>
                          <PopoverComponent>
                            <span className="flex flex-col">
                              {t('portfolio:portfolio_score_value')}: {criteria.min} - {criteria.max}{' '}
                            </span>
                          </PopoverComponent>
                        </div>
                      </div>
                    </Table.Td>
                    <Table.Td className="!pt-2 !align-top">
                      <TextareaInput
                        name={`scores.${competency.id}.criterias.${criteria.id}.feedback`}
                        placeholder={t('portfolio:work_feedback')}
                        className="className='!mt-0' h-[120px] w-full rounded-md border !border-borderColor px-4 py-2 text-base"
                      />
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table.Table>
          </div>
        ))}
        {/* <div className="mb-5 mt-6 overflow-hidden rounded border-b border-borderGrey">
          <div
            className="flex w-full cursor-pointer items-center justify-between rounded bg-card p-4 duration-100 ease-in-out"
            onClick={() => setShow(!show)}
          >
            <h2>{t('portfolio:additional_competencies')}</h2>

            <div className={`mr-2 w-fit duration-300 ease-in-out ${show ? 'rotate-[270deg]' : 'rotate-90'}`}>
              <ArrowRight />
            </div>
          </div>

          <div
            style={show ? { height: `${element?.offsetHeight}px`, overflowX: 'auto' } : { height: '0px' }}
            className="bg-card px-5 ease-in-out"
          >
            <div ref={setElement} className="py-4">
              {work.competencies?.map((competency) => (
                <div key={competency.id}>
                  <Table.Table className="mb-6 mt-3" key={competency.id}>
                    <Table.Thead>
                      <Table.Tr>
                        <Table.Th>
                          {' '}
                          <div className="flex !align-top">{t('portfolio:additional_competencies')}</div>
                        </Table.Th>
                        <Table.Th />
                        <Table.Th />
                      </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>
                      <Table.Tr>
                        <Table.Td />
                        <Table.Td />
                        <Table.Td />
                      </Table.Tr>
                    </Table.Tbody>
                  </Table.Table>
                </div>
              ))}
            </div>
          </div>
        </div> */}
        <div className="my-3 w-full">
          <TextareaInput
            name="comment"
            label={t('portfolio:comment')}
            placeholder={t('portfolio:comment')}
            className="className='!mt-0' h-[160px] w-full rounded-md border !border-borderColor px-4 py-2 text-base"
          />
        </div>
      </div>
      <div className="my-3 flex w-full justify-end">
        <Button type="submit" variant="blue" disabled={!formik.isValid || !formik.dirty}>
          {t('portfolio:evaluation')}
        </Button>
      </div>
    </>
  )
}
